import { observable, action, runInAction, computed } from 'mobx';
import request from '../utils/request'
import history from "../utils/history"
import { isWeiXin } from "../utils/BrowerUtil";
import { message } from "antd"
class EatStore {
    @observable parkList = [

    ]
    @observable payStu = false
    @observable parkIndex = 0; //标识产业园区ID
    @observable servicesIndex = 0;//标识点餐服务类型
    @observable numList = {};//选中的订单产品
    @observable rowsList = [];//订单列表
    @observable servicesType = [];
    @observable servicesDetails = {
        rows: [],
        needPickTime: false,
        name: '',
        desc: '',
        shipStartPrice: 0,
        beginSpan: 0,
        endSpan: 0
    };
    @observable orderList = [];
    @observable orderId = null;
    @observable hintMonthCoupon={//用户标准餐统计提示 如果days和coupons都为 0 就不要提示了
        days:0,
        coupons:0,
    }
    @computed
    get activeParkName() {
        let curParkName=null;
        if(this.parkList[this.parkIndex]){
            curParkName=this.parkList[this.parkIndex].name.substring(0,2);
        }

        return curParkName;
    }
    @computed
    get servicesTypeName() {
        let activeType = this.servicesType[this.servicesIndex];
        return activeType
            ? activeType.name : "套餐"
    }

    @action getParksList=async (params)=>{ //园区
        const { msg,rows}=await request({
            url: "/Parks/List",
            method: 'post',
            params
        })
        runInAction(() => {
            if (msg === "success") {
                this.parkList=rows;
            }
        })
    }

    @action
    getData = async (params, success) => {
        let { rows } = await request({
            url: "/eat/home/right"
        })
        runInAction(() => {
            if (!this.rowsList.length > 0) {
                this.rowsList = rows;
            }
        })
        //return data;
    }

    @action
    getIniData = async (authenticated) => {

        let { msg, rows } = await this.getServices({
            parkId:this.parkList[this.parkIndex].id,
            parkName: this.parkList[this.parkIndex].name
        });
        if (msg === "success") {
            runInAction(() => {
                this.servicesType = rows;
                if (this.servicesType.length > 0) {
                    this.getCategories({
                        serviceId: this.servicesType[this.servicesIndex].id
                    });
                    if(authenticated){//切换园区时，已经登录 未登录不查询优惠券提示
                        this.thisMonthCouponStat({
                            serviceId: this.servicesType[this.servicesIndex].id
                        })
                    }
                }else{
                    //清空数据
                    this.servicesDetails = {
                        rows: [],
                        needPickTime: false,
                        name: '',
                        desc: '',
                        shipStartPrice: 0,
                        beginSpan: 0,
                        endSpan: 0
                    };
                }
            })
        }
    }
    @action
    getServices = async (params) => {//点餐服务类型
        return request({
            url: "/Dinner/GetServices",
            data: params,
            method: 'post',
        })

    }
    @action
    getCategories = async (params) => {//获取服务类型详细信息
        let { msg, needPickTime, rows, name, desc, shipStartPrice, beginSpan, endSpan } = await request({
            url: "/Dinner/GetCategories",
            data: params,
            method: 'post',
        })
        runInAction(() => {
            if (msg === "success") {
                this.servicesDetails = {
                    rows,
                    needPickTime,
                    name,
                    desc,
                    shipStartPrice,
                    beginSpan,
                    endSpan
                };

                //判断是否显示点餐的时间提示
                let date = new Date();
                let curSec = (date.getHours() * 60 * 60 + date.getMinutes() * 60 + date.getSeconds()) * 1000
                let end = endSpan ? endSpan : 0;
                let begin = beginSpan ? beginSpan : 0;

                if (curSec > begin && curSec < end) {
                    this.payStu = false;
                } else {
                    this.payStu = true;
                }

            }
        })
    }
    @action
    closeTip = () => {
        //this.payStu = false
        this.hintMonthCoupon={
            days:0,
            coupons:0,
        }
    }
    @action
    listUserOrders = async (params) => {//订单列表
        return request({
            url: "/Dinner/ListUserOrders",
            data: params,
            method: 'post'
        })
    }

    @action
    listManageOrders = async (params) => {//管理员订单列表
        return request({
            url: "/Dinner/ListManageOrders",
            data: params,
            method: 'post'
        })
    }


    @action
    payCreate = async (params) => { //付款下单
        if (isWeiXin()) {
            params.accessMode = "JsApi"
        } else {
            params.accessMode = "nweb"
        }

        return request({
            url: "/ServicePayment/Create",
            method: 'post',
            data: params
        })
    }
    @action getPickCodekCode = async (params) => {
        return request({
            url: "/Dinner/GetPickCode",
            method: 'post',
            params
        })
    }

    @action
    ordersPayUrl = async (params) => {
        // params.accessMode="nweb"
        if (isWeiXin()) {
            params.accessMode = "JsApi"
        } else {
            params.accessMode = "nweb"
        }
        return request({
            url: "/ServicePayment/Pay",
            method: 'post',
            params
        });
    }

    @action
    updatePickState = async (params) => {
        return request({
            url: "/ServiceOrder/UpdatePickState",
            method: 'post',
            params
        })
    }
    ///ServicePayment/Cancel?paymentId=
    @action paymentCancel = async (params) => {
        let data = await request({
            url: "ServicePayment/Cancel",
            method: 'post',
            params
        })
    }
    @action orderCancel = async (params) => {
        return request({
            url: "/ServiceOrder/Cancel",
            method: 'post',
            params
        })
    }


    //ServiceOrder/Refund

    @action refund = async (params) => { //用户退款
        return request({
            url: "/ServiceOrder/Refund",
            method: 'post',
            params
        })
    }

    @action thisMonthCouponStat=async (params) => {//用户标准餐统计提示
     const { msg, days, coupons}=await request({
            url: "/Dinner/ThisMonthCouponStat",
            method: 'post',
            params
        })

        runInAction(() => {
            if (msg === "success") {
               this.hintMonthCoupon={
                   days,
                   coupons
               }
            }
        })
    }


}
export default new EatStore();

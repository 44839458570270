
    export const isWeiXin=function() {
        return false
    // let  ua = window.navigator.userAgent.toLowerCase();
    // // console.log(ua);
    // //mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1
    // if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    //     return true;
    // } else {
    //     return false;
    // }
}

export const paramUrl= function(name) { ///eat?authEat=true
    let curName=name;
    var search = window.location.search.substring(1)
    if (search) {
        var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
       // return name ? obj[name] : obj
        return name?obj[name]:false;
    }
}
export const storage={
    set(key,value){
        localStorage.setItem(key,JSON.stringify(value));
    },
    get(key){
        return JSON.parse(localStorage.getItem(key));
    },
    remove(key){
        localStorage.removeItem(key)
    }
}

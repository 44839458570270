import {observable, action, runInAction} from 'mobx';
import request from "../utils/request";
import ScrList from "../Component/ScrList";
import React from "react";
class DialogCouponStore {
    @observable  couponList=[];//优惠券弹窗列表
    @observable  deliveryOptions=[];//外送选项列表
    @observable couponStus=[];//优惠券状态列表

    coupon = async (params) => {//优惠券弹窗列表
       const {msg,coupons,deliveryOptions}=await request({
            url: "/ServicePayment/GetDeliveryCoupon",
            data: params,
            method: 'post'
        })
        runInAction(() => {
            if (msg === "success") {
              this.couponList=coupons;
              this.deliveryOptions=deliveryOptions;
            }
        })
    }

    @action
    calculatePrice = async (params) => {//计算优惠券
        const {msg,data}=await request({
            url: "/ServicePayment/CalculatePrice",
            data: params,
            method: 'post'
        });
        if(msg==="success"){
           return {
               msg,data
           }
        }else {
           return null
        }
    }


    @action
    couponStates = async (params) => {//优惠券状态
        const {msg,rows}=await request({
            url: "/Coupon/States",
            data: params,
            method: 'post'
        });
        runInAction(()=> {
            if(msg==="success"){
                this.couponStus=rows;
            }
        });
    }
    @action
    listUserCoupons = async (params) => {//我的优惠券
        this.myCouponListTotal=0;
        const {msg,rows,total}=await request({
            url: "/Coupon/ListUserCoupons",
            data: params,
            method: 'post'
        });
        if(msg==="success"){
            return {msg,rows,total};
        }else{
           return null;
        }
    }
}
export default new DialogCouponStore();

import loadable from 'loadable-components';
let routes = [
    {
        title: '登录',
        component: "Login",
        link: '/Login',
        roles: []
    },
    {
        title:"",
        component:"OrderDetail",
        link:'/OrderDetail',
        roles: []
    },
    {
        title:"",
        component:"OrderResult",
        link:'/OrderResult',
        roles: []
    },
    {
        title:"",
        component:"OrderList",
        link:'/OrderList',
        roles: []
    },
    {
        title:"",
        component:"Clear",
        link:'/clear',
        roles: []
    },
    {
        title:"",
        component:"Coupon",
        link:'/coupon',
        roles: []
    },
    {
        title: '',
        component: "Index",
        link: '/',
        roles: []
    },

].map(( {component,...other} )=>{
    return {options:[],...other,component:loadable(()=> import(`containers/${component}`))}
})
export default routes;
export const roles=[
   "dinnerManager",
    "admin"
];

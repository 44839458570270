import React, {Component} from 'react';
import styled from "styled-components";
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import {message} from "antd";
const Wrap =styled.div`
    display: flex;
    flex:1;
    width:100%;
    flex-direction: column;
    overflow:scroll;
    background-color: var(--ghostWhite);
    ul,li{
      margin:0;
      padding: 0;
      list-style: none;
    }
    ul{
     flex:1;
     flex-direction: column;
     display:flex;
    }
    .loader{
      color:var(--blue);
      margin-bottom: 0.1rem;
    }
    .hint-v{
        height: 100px;
        background-color: #1cbbb4;
    }
`
class ScrList extends Component{
     state={
        data:[],
         hasMore:false,
         itemHeight:0,
    }
    static propTypes={
        data:PropTypes.array,
        row:PropTypes.any,
        hasMore:PropTypes.any,
        total:PropTypes.any,
        loadMore:PropTypes.any,
        headView:PropTypes.any,
        isLoad:PropTypes.any,
        offTop:PropTypes.any,
    }
    static defaultProps={
        data:[],
        offTop:250,
        row:null,
        hasMore:false,
        isLoad:false,
        total:0,
        loadMore:()=>{},
        headView:<div/>
    }
    constructor(props){
         super(props);
         this.state.data=this.props.data;
    }
    componentDidMount(){

     }
    componentWillUnmount(){

    }
    scroll=(e)=>{
        //e.preventDefault();
       // this.props.loadMore(this.scrollObj.scrollTop);
    }
    loadFunc=()=>{
        this.props.loadMore();
    }

    addData=(curData)=>{
        this.setState({
            data:this.state.data.concat(curData)
        })
    }
    //清除数据
    clearData=(mData)=>{
        this.setState({
            data:mData
        })
    }
    render(){
        return(
            <Wrap ref={(obj)=>{ this.scrollObj=obj; }}>
                <InfiniteScroll
                    pageStart={0}
                    loadMore={this.loadFunc}
                    hasMore={false}
                    useWindow={false}
                    threshold={10}
                    loader={
                        this.state.data.length>0?
                            <div className="loader d-flex align-items-center justify-content-center" key={0}>正在加载 ...</div>:
                           null
                    }
                >
                    {this.props.headView}

                    {this.state.data.length>0?null:this.props.isLoad?null:
                        <div className={"align-items-center justify-content-center"}
                             style={{
                                 marginTop:"10%",
                                 flex:1,display:"flex"}} className="loader flex-column  d-flex align-items-center justify-content-center" key={0}>
                        <div style={{backgroundColor:"var(--blue)",height:70,width:70,borderRadius:45,marginBottom:10}}
                             className={"d-flex align-items-center justify-content-center"}>
                            <img width={35} height={35} src={"/order/img/eat.png"}/>
                        </div>
                        暂无数据
                    </div>}
                    <ul>
                        {
                            this.state.data.map((value,index)=>{
                                return(
                                    <li className={"item-li"} key={index}>
                                        {this.props.row(value,index)}
                                    </li>
                                )
                            })
                        }
                    </ul>
                </InfiniteScroll>
            </Wrap>
        )
    }
}
export default ScrList;

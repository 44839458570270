import homeStore from './homeStore'
import eatStore from './eatStore'
import userStore from './userStore'
import dialogCouponStore from "./dialogCouponStore";
const stores = {
    homeStore,
    eatStore,
    userStore,
    dialogCouponStore
}
export default stores

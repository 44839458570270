import React from 'react';
import routes from './routerConfig'
import {
  Switch,
  Route
} from "react-router-dom";
import 'css/bootstrap-tools.css';
import 'css/global.css';

function App() {
  return (
    <Switch>
      {routes.map(({ options,component, link }, dex) => {
        return <Route  {...options}  path={link}  component={component} key={dex}></Route>
      })}

    </Switch>
  );
}
export default App;

import { observable, action,runInAction} from 'mobx';
import request from '../utils/request'
import {isWeiXin} from "../utils/BrowerUtil";
class UserStore {
  @observable userInfo = {};
  @observable authNum=0;//认证次数
  @action
  getUserInfo=async (params)=>{//获取用户信息
        let {msg,phone,realName,roles,authenticated}=await request({
            url:"/Account/GetInfo"
        })
        runInAction(()=>{
              if(msg=="success"){
                  this.userInfo={
                      phone,realName,roles,authenticated
                  }
              }else{
                  this.userInfo.authFail=true;
              }
        })
    }
    @action
    sendCode=async (params)=>{//发送短信验证码
        return  request({
            url:"Account/SendCode",
            data:params,
            method:'post',
        });
    }


    @action
    login=async (params)=>{

      if(isWeiXin()){
          params.provider="WxOffiaccount";
      }
        return request({
            url:isWeiXin()?"/Account/ExternalLoginConfirmation":"/Account/Login",
            data:params,
            method:'post'
        })
    };

    @action
    logoff=async (params)=>{
        return request({
            url:"Account/Logoff",
            data:params,
            method:'post'
        })
    }
}
export default new UserStore();

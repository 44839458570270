import axios from 'axios';
import { message } from 'antd';
import history from './history';
import {isWeiXin, paramUrl} from "./BrowerUtil";
axios.defaults.withCredentials = true;
let request = (item) => {
    let { url } = item
    if (process.env.NODE_ENV !== 'development') {
        if (!item.params) {
            item.params = {}
        }
        item.params.urlPath = url
        item.url = '/api'
    }
    return axios(Object.assign(item, {
        baseURL: process.env.NODE_ENV === 'development' ? '/api/' : '/'
    })).then((resp) => {
        let { data } = resp;
        let { msg } = data;
        if (msg === 'success') {
            data.flag = true;
            return data;
        } else {
            data.flag = false;
        }
        return data;
    }).catch((resp) => {
        console.log(resp,'<=== resp');
        if(resp.response.status==401){
            if(isWeiXin()){
              /*  window.location.href="/Account/ExternalLogin?provider=WxOffiaccount&returnUrl=" +
                    "/order?"+AUTHEAT+"="+history.location.pathname;*/
                window.location.href="/WxOffiaccount/Auth?returnUrl=" +
                    "/order?"+AUTHEAT+"="+history.location.pathname;
            }else {
                let search =history.location.search.substring(1);
                let curObj={};
                if (search) {
                     curObj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
                }
                if(!curObj["broAuth"]){
                    history.push('/login?broAuth='+history.location.pathname);
                }else{
                    if(curObj["broAuth"]==="/OrderDetail"){
                        //history.push('/login?broAuth='+history.location.pathname);
                    }
                }
            }
        }
        return { flag: false };
    })
};
export const imgHost='https://www.9425.cn'; //'http://192.168.33.12:8013';"https://www.9425.cn";
export const AUTHEAT="authEat";//
//微信授权
export const getAuth=function () {
        if(isWeiXin()){
           /* window.location.href="/Account/ExternalLogin?provider=WxOffiaccount&returnUrl=" +
                "/order?"+AUTHEAT+"="+history.location.pathname*/
            window.location.href="/WxOffiaccount/Auth?returnUrl=" +
                "/order?"+AUTHEAT+"="+history.location.pathname;
           // if(curStu){
              //  history.push(curStu);
            //}
        }
}
export default request;

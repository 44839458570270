import {observable, action, runInAction} from 'mobx';
import request from "../utils/request";

class HomeStore {
    @observable inProgress = false;
    @observable errors = undefined;
    @observable routeStu="/home";
    @observable parkList = [
       /* {parkId: 1, parkName: "南昌园区"},
        {parkId: 2, parkName: "武汉园区"}*/
    ];

    @observable values = {
      username: 'xiaoming',
      email: '',
      password: '',
    };

    @action setUsername(username) {
      this.values.username = username;
    }
}

export default new HomeStore();
